import React, { ReactNode, useRef, useState } from "react";
import { Box, Flex, Icon, css } from "@storyofams/react-ui";
import styled from "styled-components";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import useMediaQuery from "~hooks/useMediaQuery";
import AnimatedCarousel from "../ Dots/AnimatedCarouselDemo";
import { ArrowRight, ArrowWhite, BlackLink, NhArrowRight } from "../Icons";

export type SliderProps = {
  children: ReactNode[];
  centerInsufficientSlides?: boolean;
  spaceBetween?: number;
  initialSlide?: number;
  small?: boolean;
  isReview?: boolean;
  isNavigationCentered?: boolean;
  isGF?: boolean;
  hasNoNavButtons?: boolean;
  hasNoControl?: boolean;
};
export const Slider = ({
  children,
  centerInsufficientSlides,
  hasNoNavButtons = false,
  spaceBetween,
  initialSlide = 0,
  small = false,
  isReview = false,
  isGF = false,
  isNavigationCentered = false,
  hasNoControl = false,
  ...props
}: any) => {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const matchesMobile: boolean = useMediaQuery("(max-width: 769px)");
  return (
    /* purgecss ignore */
    <>
      <Flex
        variant="center"
        position={"relative"}
        overflow={"visible"}
        css={css({
          width: "100%",
          // ".swiper-slide": {
          //   width: isReview ? "100%" : small ? "142px" : "305.5px",
          // },
          ".swiper-button-next, .swiper-button-prev": {
            tranform: "translateY(-50%)",
          },
        })}
      >
        <Container className="slider-container">
          <Swiper
            /* purgecss ignore */
            slidesPerView="auto"
            onSlideChange={(e) => {
              setActiveIndex(e.activeIndex);
            }}
            onInit={(core) => {
              swiperRef.current = core.el;
            }}
            className="mySwiper"
            centeredSlides={matchesMobile}
            overflow="visible"
            initialSlide={initialSlide}
            modules={[Navigation]}
            pagination={{
              dynamicBullets: true,
            }}
            spaceBetween={spaceBetween || 16}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              disabledClass: "swiper-button-disabled",
            }}
            direction="horizontal"
            mousewheel={false}
            centeredSlidesBounds={matchesMobile}
            watchOverflow
            centerInsufficientSlides={centerInsufficientSlides || matchesMobile}
            breakpoints={{
              // when window width is >= 320px
              767: {
                spaceBetween: spaceBetween || 24,
              },
            }}
            {...props}
          >
            {children &&
              children?.map((child, index) => (
                <SwiperSlide key={String(index)}>{child}</SwiperSlide>
              ))}
            {!hasNoNavButtons && (
              <Box display={["none", "block"]}>
                <NavigationButton />
                <NavigationButton direction="next" />
              </Box>
            )}
          </Swiper>
        </Container>
      </Flex>
      {!isGF && (
        <Box
          mt={3}
          width={"100%"}
          alignItems={"center"}
          display={["flex", hasNoNavButtons ? "flex" : "none"]}
          justifyContent={isNavigationCentered ? "center" : "space-between"}
        >
          <AnimatedCarousel
            initialSize={10}
            totalDots={children?.length}
            currentPlace={activeIndex}
            lastPlace={children?.length - 1}
            mainColor="black"
          />
          {!isNavigationCentered && !hasNoControl && (
            <Box>
              <Flex fontSize={"28px"}>
                <Icon
                  cursor={"pointer"}
                  mr={2}
                  transform={"rotate(180deg)"}
                  opacity={activeIndex === 0 && 0.3}
                  icon={BlackLink}
                  onClick={() => swiperRef.current.swiper.slidePrev()}
                ></Icon>
                <Icon
                  opacity={activeIndex === children?.length - 1 && 0.3}
                  onClick={() => swiperRef.current.swiper.slideNext()}
                  cursor={"pointer"}
                  icon={BlackLink}
                ></Icon>
              </Flex>
            </Box>
          )}
        </Box>
      )}
      {isGF && (
        <Flex
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          mt={"10px"}
        >
          <Icon
            fontSize={"20px"}
            transform={"rotate(180deg)"}
            icon={ArrowWhite}
            onClick={() => swiperRef.current.swiper.slidePrev()}
          ></Icon>
          <AnimatedCarousel
            width={"60px"}
            mx={"10px"}
            isNoMove
            initialSize={10}
            totalDots={children?.length}
            currentPlace={activeIndex}
            lastPlace={children?.length - 1}
            mainColor="white"
          />
          <Icon
            onClick={() => swiperRef.current.swiper.slideNext()}
            fontSize={"20px"}
            icon={ArrowWhite}
          ></Icon>
        </Flex>
      )}
    </>
  );
};

export const NavigationButton = ({ direction = "prev" }) => (
  <Box
    minWidth={"40px !important"}
    width="40px !important"
    display={["none", "block"]}
    height="40px"
    className={`swiper-button-${direction}`}
  >
    <Icon
      icon={BlackLink}
      fontSize={5}
      color="black"
      css={{
        transform: `rotate(${direction === "prev" ? "180deg" : "0deg"})`,
      }}
    />
  </Box>
);

const Container = styled.div`
  width: 100%;
  overflow: visible;
  .swiper-button-next,
  .swiper-button-prev {
    @media (max-width: 768px) {
      display: none;
    }
    top: 50%;
    transform: translateY(-50%);
    &:after {
      content: unset;
    }
  }
  .swiper-wrapper {
    overflow: visible;
  }
  .swiper-button-disabled {
    display: none;
  }
  .swiper {
    overflow: visible;
  }
`;
